import { NoSSR } from '@zep/components';
import { useLocale } from '@zep/hooks';
import { BoltFillIcon, ChevronRightIcon } from '@zep/icons';
import { SalesMapEmbed } from '@zep/module/embed';
import { FreePricingCard, PaidPricingCard } from '@zep/module/payment/Pricing/PricingSection1';
import { Button } from '@zep/ui_v3';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
export const Pricing = () => {
  const {
    locale
  } = useLocale();
  return <>
      <PricingCards data-sentry-element="PricingCards" data-sentry-source-file="Pricing.tsx" />
      {locale === 'ko' && <Inquiry />}
    </>;
};
const PricingCards = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <section data-sentry-component="PricingCards" data-sentry-source-file="Pricing.tsx">
      <div className="flex flex-col items-center gap-2xl bg-gradient-to-b from-[#F3F5F9] to-[#F8F9FC] px-md py-xl min-[751px]:px-xl min-[751px]:py-3xl">
        <div className="flex flex-col items-center gap-2xs min-[751px]:flex-row">
          <BoltFillIcon className="size-[32px] min-[751px]:size-[40px]" fill="#5D5E69" data-sentry-element="BoltFillIcon" data-sentry-source-file="Pricing.tsx" />
          <h2 className="text-center text-display-sm-extrabold text-default min-[751px]:whitespace-normal">
            {t('landing.pricingTitle')}
          </h2>
        </div>

        <div className="flex flex-col gap-lg min-[750px]:flex-row">
          <FreePricingCard data-sentry-element="FreePricingCard" data-sentry-source-file="Pricing.tsx" />
          <PaidPricingCard data-sentry-element="PaidPricingCard" data-sentry-source-file="Pricing.tsx" />
        </div>

        <Button variant="contained" intent="alternative" size="lg" rounded="default" asChild data-sentry-element="Button" data-sentry-source-file="Pricing.tsx">
          <Link href="/pricing" data-sentry-element="Link" data-sentry-source-file="Pricing.tsx">
            {t('landing.pricingBtn')}
            <ChevronRightIcon size="md" data-sentry-element="ChevronRightIcon" data-sentry-source-file="Pricing.tsx" />
          </Link>
        </Button>
      </div>
    </section>;
};
const Inquiry = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <section className="flex flex-col items-center gap-xs bg-neutral px-md pb-4xl pt-[24px]" data-sentry-component="Inquiry" data-sentry-source-file="Pricing.tsx">
      <div className="flex flex-col items-center gap-md">
        <h2 className="text-center text-heading-xl-extrabold text-default">
          ZEP QUIZ 도입이 고민이신가요?
          <br />
          선생님을 위한 최적의 솔루션을 제공해 드려요
        </h2>
        <p className="text-center text-body-lg-medium text-neutral">
          아래 양식을 작성 후 제출해 주시면
          <br />
          담당 매니저가 빠른 시일 내로 상담을 도와드리겠습니다.
        </p>
      </div>

      <NoSSR data-sentry-element="NoSSR" data-sentry-source-file="Pricing.tsx">
        <SalesMapEmbed webFormUrl={t('common:home.pricing.contactLink')} className="w-full max-w-[568px]" data-sentry-element="SalesMapEmbed" data-sentry-source-file="Pricing.tsx" />
      </NoSSR>
    </section>;
};